import React from 'react'
import { useStoreAction } from './reducer'

export const StoreContext = React.createContext([{}, {}])

const StoreProvider = ({ children }) => {
  const stores = useStoreAction()

  return (
    <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
  )
}

export default StoreProvider
export const useStore = () => React.useContext(StoreContext)
