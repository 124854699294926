import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import './carousel.min.css'
import {
  ImagePreviewBox,
  ThumbImage,
  ThumbContainer,
  LabelContainer,
  ImagePreviewHeader,
  ImagePreviewTitle,
  ToggleWrapper,
  ToggleLabel
} from './ImagePreview.style'
import { Toggle } from 'rsuite'
import Overlay from './Overlay/Overlay'
import { useTranslation } from 'react-i18next'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import { isVideoFormat } from '@core/utils/file-format'

const PDFImg = '/images/pdf-icon.gif'
const VideoImg = '/images/video-icon.gif'

const ImagePreview = React.forwardRef(
  ({ data, side, cameraGuideImage, setIndexImage, indexImage }, ref) => {
    const { t } = useTranslation()
    const [isShowOverlay, setIsShowOverlay] = useState(true)

    const thumbsHandler = (item) => {
      let src
      if (item.url.includes('.pdf')) {
        src = PDFImg
      } else if (isVideoFormat(item.url)) {
        src = VideoImg
      } else {
        src = item.url
      }
      return <ThumbImage src={src} alt={item.label} />
    }

    const renderCustomThumbs = () => {
      const thumbList = data.map((item, index) => (
        <ThumbContainer key={item.label}>
          {thumbsHandler(item)}
          <LabelContainer>{item.label}</LabelContainer>
        </ThumbContainer>
      ))

      return thumbList
    }

    const handleOnClickImage = async (imgUrl) => {
      let url
      if (imgUrl.includes('.pdf')) {
        url = imgUrl
      } else if (isVideoFormat(imgUrl)) {
        return null
      } else {
        url = `/preview-image?imgUrl=${encodeURIComponent(imgUrl)}`
      }
      window.open(url, '_blank')
    }

    const getImageName = (data) => {
      if (data) {
        const splitQuestionMark = data[0]?.url.split('?')
        const splitSlash = splitQuestionMark[0]?.split('/')
        const imageName = splitSlash[splitSlash.length - 1]
        const imageNameSubstr = imageName.substr(0, 18)
        return imageNameSubstr
      }
      return null
    }

    const displayHandler = (item) => {
      if (isVideoFormat(item.url)) {
        return <VideoPlayer url={item.url} />
      } else {
        let src
        let height
        if (item.url.includes('.pdf')) {
          src = PDFImg
          height = 200
        } else {
          src = item.url
          height = 400
        }
        return (
          <img
            src={src}
            alt={item.label}
            style={{
              width: 'auto',
              height: height
            }}
          />
        )
      }
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ImagePreviewHeader>
          <ToggleWrapper>
            <ToggleLabel>{t('page-search.photoModal.overlay')}:</ToggleLabel>
            <Toggle
              size="md"
              checked={isShowOverlay}
              checkedChildren={t('page-search.photoModal.open')}
              unCheckedChildren={t('page-search.photoModal.close')}
              onChange={setIsShowOverlay}
              style={{ fontSize: 12 }}
            />
          </ToggleWrapper>
          <ImagePreviewTitle>
            <b>{t('page-search.photoModal.pictureName')}:</b>
            <div
              style={{
                fontSize: 14
              }}
            >
              {getImageName(data) || '-'}
            </div>
          </ImagePreviewTitle>
        </ImagePreviewHeader>
        <Carousel
          infiniteLoop
          emulateTouch
          ref={ref}
          id="image-preview"
          renderThumbs={renderCustomThumbs}
          thumbWidth={100}
          // onClickItem={(index) => setIndexImage(index)}
          // onClickThumb={(index) => setIndexImage(index)}
          // onChange={(selectedIndex) => setIndexImage(selectedIndex)}
          // statusFormatter={(current, total) => {
          //   setIndexImage(current - 1)
          //   return current
          // }}
        >
          {data?.length > 0 &&
            data.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOnClickImage(item.url)}
                  >
                    <ImagePreviewBox id="image-preview-box">
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}
                      >
                        {isShowOverlay && cameraGuideImage && index === 0 && (
                          <Overlay
                            side={side}
                            cameraGuideImage={cameraGuideImage}
                          />
                        )}
                        {displayHandler(item)}
                      </div>
                    </ImagePreviewBox>
                  </div>
                </div>
              )
            })}
        </Carousel>
      </div>
    )
  }
)

export default ImagePreview
