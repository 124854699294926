import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from '@core/hooks'

import { Container } from 'rsuite'
import { AccountStatus, Navigator, LocalChanger } from '@components/molecules'
import { Sidebar, Tools } from './Layout.style'

import { noop } from 'lodash'

const Layout = ({
  children,
  username,
  lastLogin,
  activeKey,
  menu = [],
  signedIn = false,
  onSelect = noop
}) => {
  const { t } = useTranslation()
  const supportedScreen = useMedia(['(min-width: 534px)'], [true], false)

  if (!supportedScreen)
    return <div className="screen center">{t('t.screen-not-support')}</div>

  return (
    <Container className="min-screen">
      <Sidebar className="center-x min-screen-y" width={64}>
        <Navigator menu={menu} activeKey={activeKey} onSelect={onSelect} />
      </Sidebar>
      <Container>
        {signedIn && (
          <Tools>
            <LocalChanger />
            <AccountStatus
              name={username}
              date={lastLogin}
              style={{ marginLeft: 35 }}
            />
          </Tools>
        )}
        {children}
      </Container>
    </Container>
  )
}

export default Layout
