import styled from '@emotion/styled'

export const PaginationContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
