import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxHeader,
  ButtonGroup,
  LoaderContainer
} from './ColumnSettingForm.style'
import { Button } from '@components/atoms'
import { theme } from '@core/styles'
import API from '@core/api'
import { useAsync } from '@core/hooks'
import { Alert, Loader, Modal } from 'rsuite'

const defaultSelect = [
  'vinNo',
  'model',
  'submitDate',
  'part',
  'symptomJudgement',
  'submitBy',
  'country',
  'dealer'
]

const ColumnSettingForm = ({ handleClose, refetchTable }) => {
  const [allColumn, setAllColumn] = useState([
    'vinNo',
    'model',
    'katashiki',
    'submitDate',
    'part',
    'diagnosisMethod',
    'submitBy',
    'country',
    'dealer',
    'branch',
    'symptomJudgement'
  ])
  const [value, setValue] = useState(['vinNo', 'submitDate'])
  const { t } = useTranslation()

  const { pending: loading } = useAsync(API.commons.column.getColumnConfig, {
    immediate: true,
    onSuccess: ({ data }) => {
      setAllColumn(data.columnConfigTemplates)
      setValue(data.columnConfigs)
    }
  })

  const { pending: submitLoading, execute: onSubmit } = useAsync(
    API.commons.column.updateColumnConfig,
    {
      onSuccess: ({ data }) => {
        refetchTable()
        Alert.success(t('t.column-has-been-configured'))
        handleClose()
      }
    }
  )

  const handleCheckAll = (v1, checked) =>
    setValue(checked ? allColumn : defaultSelect)
  const handleChange = (v2) => setValue(v2)
  return (
    <>
      <Modal.Body style={{ height: 400, marginBottom: 40 }}>
        <CheckboxHeader>
          <Checkbox
            id="checkbok-all"
            indeterminate={value.length > 0 && value.length < allColumn.length}
            checked={value.length === allColumn.length}
            onChange={handleCheckAll}
            style={{ fontSize: 16 }}
          >
            {t('t.select-all')}
          </Checkbox>
        </CheckboxHeader>
        <hr />
        {loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <CheckboxGroup
            id="checkbok-container"
            name="checkboxList"
            value={value}
            onChange={handleChange}
          >
            {allColumn.map((item) => {
              return (
                <Checkbox
                  id={`checkbox-${item}`}
                  key={item}
                  value={item}
                  disabled={
                    item === 'vinNo' ||
                    item === 'submitDate' ||
                    item === 'model' ||
                    item === 'symptomJudgement' ||
                    item === 'part' ||
                    item === 'country' ||
                    item === 'submitBy' ||
                    item === 'dealer'
                  }
                  style={{ fontSize: 16 }}
                >
                  {t(`page-search.table.${item}`)}
                </Checkbox>
              )
            })}
          </CheckboxGroup>
        )}
      </Modal.Body>
      <Modal.Footer className="text-center">
        <ButtonGroup>
          <Button
            id="reset-button"
            data-testid="reset-button"
            onClick={() => {
              setValue(defaultSelect)
            }}
            disabled={submitLoading}
            style={{
              background: theme.colors.LIGHT_GRAY,
              color: theme.colors.BLACK,
              width: '40%'
            }}
          >
            {t('t.reset-button')}
          </Button>
          <Button
            onClick={() => {
              onSubmit(value)
            }}
            loading={submitLoading}
            id="save-button"
            data-testid="save-button"
            style={{ width: '45%' }}
          >
            {t('t.save-button')}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </>
  )
}

export default ColumnSettingForm
