import React from 'react'
import { Whisper } from 'rsuite'
import { BasePopover } from './Popover.style'
import PopoverContent from '../ImagePreviewForm/TrashIcon/PopoverContent'

// THIS POPOVER WILL USE REF FROM PARENT
const Popover = ({
  className,
  placement = 'auto',
  title,
  content,
  children = <div />,
  trigger = 'hover',
  isCustomize = false,
  onClick,
  renderFlag,
  ...props
}) => {
  const ref = React.useRef()

  React.useEffect(() => {
    handleClick()
  }, [renderFlag])

  const handleClick = () => {
    ref.current.close()
  }

  const childContent = React.useMemo(() => {
    if (typeof children === 'string') {
      return <Popover>{children}</Popover>
    }

    return children
  }, [children])

  return (
    <Whisper
      className={className}
      placement={placement}
      trigger={trigger}
      ref={ref}
      speaker={
        isCustomize ? (
          <PopoverContent handleClick={onClick} />
        ) : (
          <BasePopover title={title}>{content}</BasePopover>
        )
      }
      {...props}
    >
      {childContent}
    </Whisper>
  )
}

export default Popover
