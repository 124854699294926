import Axios from 'axios'
import { cleanQueryParams, cleanBody } from '@core/helpers/function'

import { ENDPOINT_API } from '@configs/env'
import { getToken, getLanguage } from './storage'
import { SUPPORT_LANGUAGES } from '@locales'

const timeout = 60000
const headers = {
  Pragma: 'no-cache', // HTTP 1.0.
  'Cache-Control': 'no-cache, no-store, must-revalidate' // HTTP 1.1.
}

const axios = Axios.create({
  baseURL: ENDPOINT_API,
  headers,
  timeout
})

const pureAxios = Axios.create({
  headers
})

export const errorInterceptor = ({
  response: { data, status } = {},
  code: AXIOS_CODE,
  ...others
}) => {
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    status,
    data,
    others
  })
}

export const requestInterceptor = (configs) => {
  const backlistHeaders = ['/auth/user/ticket'].includes(configs.url)
  const jwtToken = getToken()

  if (!backlistHeaders && jwtToken) {
    configs.headers = {
      ...configs.headers,
      Token: jwtToken,
      Language: getLanguage() || SUPPORT_LANGUAGES[0]
    }
  }

  if (configs.params) {
    configs.params = cleanQueryParams(configs.params)
  }

  if (configs.data) {
    configs.data = cleanBody(configs.data)
  }

  return configs
}

export const responseInterceptor = ({ data, status, ...others }) => {
  const isFailure =
    /\w+ERR/.test(data.responseCode) || (data?.httpStatusCode || status) !== 200

  if (isFailure) return Promise.reject(data)

  return Promise.resolve(data)
}

axios.interceptors.request.use(requestInterceptor, errorInterceptor)
axios.interceptors.response.use(responseInterceptor, errorInterceptor)
export { axios as default, pureAxios }
