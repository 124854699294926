import React from 'react'
import { Form, FormGroup, FormControl, SelectPicker, Loader } from 'rsuite'
import {
  FormControlLabel,
  ImagePreviewFormWrapper,
  CommentListLayout,
  FormGroupFlex
} from './ImagePreviewForm.style'
import { Button } from '@components/atoms'
import { useTranslation } from 'react-i18next'
import { LineHorizontal } from '../Report/Report.style'
import TrashIcon from './TrashIcon/TrashIcon'
import { LoaderContainer } from '@components/organisms/Modal/ReportModal/ReportModal.style'
import { useAuthObserver } from '@core/hooks'

const judgementOption = ['OK', 'NG'].map((item) => ({
  label: item,
  value: item
}))

const ImagePreviewForm = ({
  commentList = [],
  handleCreateComment,
  handleRemove,
  renderFlag,
  designGroupOption,
  formValue,
  setFormValue,
  handleChangeJudgement,
  loadingComment,
  loadingFrom
}) => {
  const { t } = useTranslation()
  const { user, isTDEMRole, isAdminRole } = useAuthObserver()
  const userId = user?.userId

  const formatDate = (isoDate) => {
    const date = new Date(isoDate)
    return `${t('page-search.photoModal.date')}: ${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${t(
      'page-search.photoModal.time'
    )}: ${date.getHours()}:${date.getMinutes()}`
  }

  return (
    <ImagePreviewFormWrapper>
      <CommentListLayout
        style={{
          justifyContent: commentList.length === 0 ? 'center' : 'start'
        }}
      >
        {loadingComment ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : commentList.length > 0 ? (
          commentList.map((comment, index) => (
            <article
              key={comment.id}
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                gap: 16,
                padding: 20,
                border: '1px solid #dedede',
                backgroundColor: '#fff',
                borderRadius: 5
              }}
            >
              {(comment.ownerUserId === userId || isTDEMRole) && (
                <TrashIcon
                  onClick={(event) => handleRemove(event, comment.id)}
                  renderFlag={renderFlag}
                />
              )}
              <span>
                <b className="title">
                  {t('page-search.photoModal.technicianDesignGroup')}:{' '}
                </b>
                <b className="technical-design-group">
                  {comment.group.toString()}
                </b>
              </span>
              <p className="comment">{comment.comment}</p>
              <span className="create-at">
                {t('page-search.photoModal.createBy')} {comment.createdBy}
                <div>{formatDate(comment.createdAt)}</div>
              </span>
            </article>
          ))
        ) : (
          <div style={{ fontSize: '18px', textAlign: 'center' }}>
            {t('page-search.alert.comment.noComment')}
          </div>
        )}
      </CommentListLayout>
      <LineHorizontal />
      <Form
        fluid
        formDefaultValue={{
          ...formValue,
          comment: sessionStorage.getItem('comment')
        }}
        onSubmit={(_, value) => handleCreateComment(value)}
        style={{ width: '100%' }}
      >
        <FormGroupFlex>
          <FormGroup style={{ width: '100%' }}>
            <FormControlLabel>
              {t('page-search.photoModal.judgement')}
            </FormControlLabel>
            <FormControl
              onChange={handleChangeJudgement}
              name="judgement"
              accepter={SelectPicker}
              data={judgementOption}
              searchable={false}
              cleanable={false}
              disabled={loadingFrom || !isAdminRole}
              style={{ width: '100%' }}
            />
          </FormGroup>
        </FormGroupFlex>
        <FormGroupFlex>
          <FormGroup style={{ width: '100%' }}>
            <FormControlLabel>
              {t('page-search.photoModal.technicianDesignGroup')}
            </FormControlLabel>
            <FormControl
              onChange={(value) =>
                setFormValue((prev) => ({
                  ...prev,
                  designGroups: value
                }))
              }
              name="designGroups"
              accepter={SelectPicker}
              data={designGroupOption}
              searchable={false}
              cleanable={false}
              disabled={loadingFrom}
              style={{ width: '100%' }}
            />
          </FormGroup>
        </FormGroupFlex>
        <FormGroup>
          <FormControlLabel id="button-submit">
            {t('page-search.photoModal.technicianComment')}
          </FormControlLabel>
          <FormControl
            rows={3}
            name="comment"
            onChange={(value) => {
              sessionStorage.setItem('comment', value)
            }}
            disabled={loadingFrom}
            componentClass="textarea"
          />
        </FormGroup>
        <FormGroup
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Button
            style={{ width: 150, marginLeft: 'auto' }}
            type="submit"
            disabled={loadingFrom}
          >
            {t('page-search.photoModal.send')}
          </Button>
        </FormGroup>
      </Form>
    </ImagePreviewFormWrapper>
  )
}

export default ImagePreviewForm
