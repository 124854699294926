const {
  REACT_APP_APP_STATE: mode = 'development',
  REACT_APP_ENDPOINT_API,
  REACT_APP_STORAGE_SECRET_KEY
} = process.env
const isProduction = mode === 'production'

const CONST = {
  development: {
    ENDPOINT_API: REACT_APP_ENDPOINT_API,
    STORAGE_SECRET_KEY: REACT_APP_STORAGE_SECRET_KEY
  },
  staging: {
    ENDPOINT_API: REACT_APP_ENDPOINT_API,
    STORAGE_SECRET_KEY: REACT_APP_STORAGE_SECRET_KEY
  },
  production: {
    ENDPOINT_API: REACT_APP_ENDPOINT_API,
    STORAGE_SECRET_KEY: REACT_APP_STORAGE_SECRET_KEY
  }
}

const ENV_CONSTANTS = CONST[mode]

Object.keys(ENV_CONSTANTS).forEach(
  (key) => (module.exports[key] = ENV_CONSTANTS[key])
)
module.exports.isProduction = isProduction
