import React, { useEffect, useState } from 'react'
import { BaseInput } from './Input.style'
import { noop } from 'lodash'

const Input = ({
  onChange = noop,
  htmlType,
  type,
  onlyAlphabet,
  value,
  ...props
}) => {
  const [_value, setValue] = useState(value)

  const handleChange = (validate) => (v) => {
    const valid = validate(v)
    let val = v

    if (!valid) {
      val = v.slice(0, -1)
    }

    setValue(val)
    onChange(val)
  }

  const checkNumber = (v) => !(isNaN(v) || v === '')

  const checkAlphabet = (v) => /[A-Za-z0-9]/g.test(v)

  useEffect(() => {
    if (typeof value !== 'undefined') setValue(value)
  }, [value])

  return (
    <BaseInput
      {...props}
      type={htmlType}
      value={_value}
      onChange={
        type === 'number'
          ? handleChange(checkNumber)
          : onlyAlphabet
          ? handleChange(checkAlphabet)
          : onChange
      }
    />
  )
}

export default Input
