import React from 'react'
import { useTranslation } from 'react-i18next'

import { Table as BaseTable } from 'rsuite'
import { Table, Pagination } from './DynamicTable.style'

import { css } from '@emotion/react'

const { Column, HeaderCell, Cell } = BaseTable

const DynamicTable = React.forwardRef(
  (
    {
      style,
      columns = [],
      data = [],
      page = 1,
      total,
      displayLength = 20,
      renderPaginationToolbar,
      onChangePage,
      mapper = ([key, label], index) => {
        return (
          <Column align="center" key={key}>
            <HeaderCell>{label}</HeaderCell>
            <Cell dataKey={key} />
          </Column>
        )
      },
      ...props
    },
    ref
  ) => {
    const refTableWrapper = React.useRef()
    const refContainer = React.useRef()
    const refTable = React.useRef()

    const { t } = useTranslation()

    React.useImperativeHandle(ref, () => ({
      refs: {
        root: refContainer.current,
        container: refTableWrapper.current,
        table: refTable.current
      }
    }))

    return (
      <div ref={refContainer} style={style}>
        {total ? (
          <Pagination
            showLengthMenu={false}
            showInfo={false}
            {...(renderPaginationToolbar && {
              renderTotal: renderPaginationToolbar,
              showInfo: true,
              css: css`
                .rs-divider {
                  display: none;
                }
              `
            })}
            ellipsis
            boundaryLinks
            activePage={page}
            displayLength={displayLength}
            total={total}
            onChangePage={onChangePage}
            summary={t('table.summary', { count: total })}
          />
        ) : null}
        <div ref={refTableWrapper}>
          {columns?.length ? (
            <Table
              autoHeight
              data={data}
              {...props}
              ref={refTable}
              renderEmpty={() => (
                <div className="full center">{t('table.empty')}</div>
              )}
            >
              {columns.map(mapper)}
            </Table>
          ) : null}
        </div>
      </div>
    )
  }
)

export default DynamicTable
