import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMAT } from '@configs/constants'

const AccountStatus = ({ name, date, ...props }) => {
  const { t } = useTranslation()

  return (
    <div style={{ fontSize: 14, textAlign: 'right' }} {...props}>
      <div>
        <span>{t('t.welcome')}</span>
        <span className="text-bold" style={{ marginLeft: 8 }}>
          {name}
        </span>
      </div>
      <div>{moment(date).format(FORMAT.STATUS)}</div>
    </div>
  )
}

export default AccountStatus
