import React from 'react'
// import { useTranslation } from 'react-i18next'
import {
  BoxContainer,
  QuestionContainer,
  QuestionLabel
} from './Question.style'

const FileAttachmentsQuestion = ({ data }) => {
  // const { t } = useTranslation()

  return (
    <BoxContainer id="file-attachments-question">
      <QuestionContainer>
        <QuestionLabel>{data.question}</QuestionLabel>
      </QuestionContainer>
    </BoxContainer>
  )
}

export default FileAttachmentsQuestion
