import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, IconButton } from 'rsuite'

const getColorTheme = ({ isButton, appearance, $color, theme }) => {
  switch (appearance) {
    case 'default':
    case undefined:
      return css`
        &,
        :hover,
        :active,
        :focus {
          background: ${$color || theme.colors.RED_RIBBON};
          color: ${theme.colors.WHITE};
        }

        ${isButton &&
        `
          & > .rs-icon {
            background: transparent !important;
          }
        `}
      `
    case 'subtle':
      return css`
        :hover,
        :active,
        :focus {
          background: ${$color || theme.colors.RED_RIBBON};
          color: ${theme.colors.WHITE};
        }
      `
    case 'ghost':
      return css`
        border-color: ${$color || theme.colors.RED_RIBBON};
        color: ${$color || theme.colors.RED_RIBBON};
        &.rs-btn-ghost:active:hover,
        :hover,
        :active,
        :focus {
          border-color: ${$color || theme.colors.RED_RIBBON};
          color: ${$color || theme.colors.RED_RIBBON};
        }

        :hover,
        :active {
          background: ${theme.colors.ATHENS_GRAY};
        }
      `
    case 'link':
    default:
      return css``
  }
}

export const BaseButton = styled(Button)`
  padding: 7px 10px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: bold;
  min-height: 38px;
  transition: transform 0.17s;

  :hover {
    transform: translateY(-1px);
  }

  :active {
    transform: translateY(1px);
  }

  ${({ $color, appearance, theme }) =>
    getColorTheme({ appearance, $color, theme })}
`

export const BaseIconButton = styled(IconButton)`
  padding: 7px 10px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: bold;
  min-height: 38px;

  &.rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 36px;
  }

  & > .rs-icon,
  &.rs-btn.rs-btn-icon > .rs-icon,
  &.rs-btn-ghost.rs-btn.rs-btn-icon > .rs-icon {
    width: 15px;
    height: 15px;
    margin-left: 12px;
  }

  ${({ $color, appearance, theme }) =>
    getColorTheme({ isButton: true, appearance, $color, theme })}
`
