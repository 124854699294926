import React from 'react'
import { useMedia } from '@core/hooks'

import { Modal } from 'rsuite'
import { Button } from '@components/atoms'

import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'

const ModalForm = React.forwardRef(
  (
    {
      defaultVisible,
      component,
      title,
      btnLabel,
      onSubmit = noop,
      modalProps = {},
      ...props
    },
    ref
  ) => {
    const [visible, setVisible] = React.useState(defaultVisible)
    const { t } = useTranslation()
    const size = useMedia(
      [
        '(max-width: 770px) and (orientation: portrait)' // tablet portrait size
      ],
      ['sm'],
      'lg'
    )

    const form = React.useRef()

    const onConfirm = () => {
      form.current?.submit()
    }

    const handleSubmit = (formValue) => {
      onSubmit(formValue)
      setVisible(false)
    }

    React.useImperativeHandle(ref, () => ({
      refs: {
        form
      },
      visible,
      open: () => setVisible(true),
      close: () => setVisible(false)
    }))

    return (
      <Modal
        size={size}
        {...modalProps}
        show={visible}
        onHide={() => setVisible(false)}
      >
        {title && (
          <Modal.Header>
            <div style={{ fontSize: '25px', fontWeight: 'bold' }}>{title}</div>
          </Modal.Header>
        )}
        <Modal.Body>
          {React.createElement(component, {
            ...props,
            ref: form,
            onSubmit: handleSubmit
          })}
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button onClick={onConfirm} style={{ padding: '10px 50px' }}>
            {btnLabel || t('t.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
)

export default ModalForm
