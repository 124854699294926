import { theme } from '@core/styles'
import styled from '@emotion/styled'

export const ImagePreviewFormWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

export const FormControlLabel = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 700;
`

export const FormGroupInline = styled.p`
  display: flex;
  gap: 20px;
`

export const FormGroupFlex = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const CommentListLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  overflow-y: auto;
  height: 400px;
  gap: 10px;
  .title {
    font-size: 18px;
    color: ${theme.colors.SELECTED_BLUE};
  }
  .technical-design-group {
    font-size: 18px;
  }
  .comment {
    font-size: 16px;
  }
  .create-at {
    font-size: 14px;
  }
`
