import React from 'react'
import {
  BoxContainer,
  QuestionContainer,
  AnswerContainer,
  QuestionLabel,
  AnswerLabel
} from './Question.style'

const TextFieldQuestion = ({ data }) => {
  return (
    <BoxContainer id="text-field-question">
      <QuestionContainer>
        <QuestionLabel>{data.question}</QuestionLabel>
      </QuestionContainer>
      <AnswerContainer>
        <AnswerLabel>{data.value}</AnswerLabel>
      </AnswerContainer>
    </BoxContainer>
  )
}

export default TextFieldQuestion
