import React from 'react'
import { Title, ModalContainer } from './TModal.style'
import { Modal } from 'rsuite'

const TModal = ({
  show,
  handleClose,
  bodyComponent,
  footerComponent,
  title,
  size,
  ...props
}) => {
  return (
    <Modal size={size} {...props} show={show} onHide={handleClose}>
      <ModalContainer>
        {title && (
          <Modal.Header>
            <Title>{title}</Title>
          </Modal.Header>
        )}
        {React.createElement(bodyComponent)}
      </ModalContainer>
    </Modal>
  )
}

export default TModal
