import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'rsuite'

import { css } from '@emotion/react'
import { setLanguage } from '@configs/storage'
import { SUPPORT_LANGUAGES } from '@locales'

const toUpper = (txt = '') => txt.toUpperCase()

const LocalChanger = () => {
  const { i18n } = useTranslation()

  const onSelect = (lang) => {
    i18n.changeLanguage(lang)
    setLanguage(lang)
  }

  return (
    <Dropdown
      activeKey={i18n.language}
      title={toUpper(i18n.language)}
      onSelect={onSelect}
      css={css`
        .rs-btn {
          font-size: 16px;
          font-weight: bold;
          color: black;
        }
      `}
    >
      {SUPPORT_LANGUAGES.map((lang) => {
        return (
          <Dropdown.Item key={lang} eventKey={lang}>
            {toUpper(lang)}
          </Dropdown.Item>
        )
      })}
    </Dropdown>
  )
}

export default LocalChanger
