import React from 'react'
import {
  getUser,
  setToken,
  setUser,
  removeToken,
  removeUser,
  getLanguage
} from '@configs/storage'
import { ACCOUNT_ROLE } from '@configs/constants'

const TYPES = {
  SET_USER: '@USER/SET',
  REVOKE_USER: '@USER/REVOKE',
  SET_LANG: '@LANGUAGE/SET'
}

const initialState = {
  user: getUser(),
  language: getLanguage()
}

export const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_USER: {
      const { userInfo, ticketId } = action.payload
      const user = { ...userInfo }

      if (user.branch !== 'tmap-em') {
        user.role =
          user.company === 'toyota'
            ? ACCOUNT_ROLE.DISTRIBUTOR
            : ACCOUNT_ROLE.DEALER
      }

      setUser(user)
      setToken(ticketId)

      return {
        ...state,
        user: user
      }
    }

    case TYPES.REVOKE_USER: {
      removeToken()
      removeUser()

      return {
        ...state,
        user: null
      }
    }

    case TYPES.SET_LANG: {
      return {
        ...state,
        language: action.payload
      }
    }

    default:
      throw new Error('Unhandle action: ', action)
  }
}

export const useStoreAction = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return React.useMemo(
    () => [
      state,
      {
        setUser(user = {}) {
          dispatch({
            type: TYPES.SET_USER,
            payload: user
          })
        },
        revokeUser() {
          dispatch({
            type: TYPES.REVOKE_USER
          })
        },
        setLanguage(lang) {
          dispatch({
            type: TYPES.SET_LANG,
            payload: lang
          })
        }
      }
    ],
    [state, dispatch]
  )
}
