import styled from '@emotion/styled'
import { Sidebar as BaseSidebar } from 'rsuite'

export const Tools = styled('div')`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
`

export const Sidebar = styled(BaseSidebar)`
  background: #eb0a1e;
`
