import React, { useEffect } from 'react'
import { Table } from 'rsuite'
import { DynamicTable } from '@components/molecules/Tables'
import { Checkbox, HeaderCellCustom } from './SelectTable.style'
import { isEqual, noop, omit } from 'lodash'

const { Column, HeaderCell, Cell } = Table

const SelectTable = React.forwardRef(
  (
    {
      rowKey = '_id',
      data = [],
      columns: cols,
      onSelect = noop,
      colProps,
      setReportDetail,
      handleReportModalOpen,
      ...props
    },
    ref
  ) => {
    const [isSelectAll, setIsSelectAll] = React.useState(false)
    const [selectedKeys, setSelectedKeys] = React.useState([])
    const [selectedItems, setSelectedItems] = React.useState([])

    const savedOnSelect = React.useRef(onSelect)
    const refTable = React.useRef()

    const allKeys = React.useMemo(
      () => data?.map((item) => item[rowKey]).sort(),
      [data]
    )

    useEffect(() => {
      if (props.onSelectItems) {
        props.onSelectItems(selectedItems)
      }
    }, [selectedItems])

    const handleCheck = React.useCallback(
      (rowData) => (_, checked) => {
        if (!checked) setIsSelectAll(false)

        const keyIndex = rowData[rowKey]
        setSelectedKeys((prev) => {
          const set = new Set([...prev])
          set.has(keyIndex) ? set.delete(keyIndex) : set.add(keyIndex)

          const selected = Array.from(set).sort()

          setIsSelectAll(isEqual(selected, allKeys))

          return selected
        })

        setSelectedItems((prev) => {
          const newData = prev

          const index = newData.findIndex((item) => item._id === rowData._id)

          if (index > -1) {
            newData.splice(index, 1)

            return newData
          } else {
            newData.push(rowData)

            return newData
          }
        })
      },
      [allKeys]
    )

    const handleCheckAll = React.useCallback(
      (_, checked) => {
        setIsSelectAll(checked)
        setSelectedKeys(checked ? allKeys : [])
        setSelectedItems(checked ? data : [])
        if (props.onCheckAll) {
          props.onCheckAll(checked)
        }
      },
      [allKeys]
    )

    const columns = React.useMemo(
      () => (Array.isArray(cols) ? [[rowKey, rowKey], ...cols] : cols),
      [cols]
    )

    const redTextNG = (text) => {
      const splitComma = text.split(',')
      const toRed = splitComma.map((item) => {
        if (item.includes('NG')) {
          return `<span style="color: red; font-weight: bold;">${item}</span>`
        } else {
          return item
        }
      })
      return toRed.toString()
    }

    const mapper = React.useCallback(
      ([key, label], index) => {
        const indeterminate =
          !!selectedKeys.length && selectedKeys.length < data.length

        const _colProps = {
          align: 'center',
          key,
          ...colProps
        }

        if (key === 'vinNo') {
          return (
            <Column
              align="center"
              verticalAlign="middle"
              key={key}
              width={200}
              fixed
            >
              <HeaderCell>{label}</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <span
                      style={{
                        color: '#0058FF',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setReportDetail(rowData)
                        handleReportModalOpen()
                      }}
                    >
                      {rowData[key]}
                    </span>
                  )
                }}
              </Cell>
            </Column>
          )
        }

        return !index ? (
          <Column
            {...omit(_colProps, ['sortable'])}
            fixed
            width={70}
            verticalAlign="middle"
          >
            <HeaderCell style={{ padding: 0 }}>
              {!data?.length ? (
                <div />
              ) : (
                <div>
                  <Checkbox
                    inline
                    checked={isSelectAll}
                    indeterminate={indeterminate}
                    onChange={handleCheckAll}
                  />
                </div>
              )}
            </HeaderCell>
            <CheckCell
              dataKey={key}
              checkedKeys={selectedKeys}
              onChange={handleCheck}
            />
          </Column>
        ) : (
          <Column
            {..._colProps}
            verticalAlign="middle"
            flexGrow={columns.length > 9 ? undefined : 1}
          >
            <HeaderCellCustom>{label}</HeaderCellCustom>
            {/* FIX DATA COLUMN STYLE */}
            <Cell dataKey={key}>
              {(rowData) => {
                if (key === 'judgementDesign') {
                  return (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: redTextNG(rowData[key])
                      }}
                    ></span>
                  )
                } else {
                  return <span>{rowData[key]}</span>
                }
              }}
            </Cell>
          </Column>
        )
      },
      [
        selectedKeys,
        isSelectAll,
        colProps,
        data.length,
        handleCheck,
        handleCheckAll
      ]
    )

    React.useEffect(() => {
      savedOnSelect.current = onSelect
    }, [onSelect])

    React.useEffect(() => {
      savedOnSelect.current(selectedKeys)
    }, [selectedKeys])

    const resetSelected = () => {
      setIsSelectAll(false)
      setSelectedItems([])
      setSelectedKeys([])
    }

    React.useImperativeHandle(
      ref,
      () => ({
        ...refTable.current,
        state: { selectedKeys, selectedItems, isSelectAll },
        setSelectedKeys,
        setIsSelectAll,
        setSelectedItems,
        resetSelected
      }),
      [selectedKeys]
    )

    return (
      <DynamicTable
        {...props}
        columns={columns}
        data={data}
        mapper={mapper}
        ref={refTable}
      />
    )
  }
)

const CheckCell = ({
  rowData,
  onChange,
  checkedKeys,
  dataKey,
  columns,
  ...props
}) => {
  const checked = checkedKeys.some((item) => item === rowData[dataKey])

  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: '46px' }}>
        <Checkbox
          value={rowData[dataKey]}
          inline
          onChange={onChange(rowData)}
          checked={checked}
        />
      </div>
    </Cell>
  )
}

export default SelectTable
