import { theme } from '@core/styles'
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef
} from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination, Modal } from 'rsuite'
import {
  RowContainer,
  ColContainer,
  LineDivider,
  QuestionLabel,
  QuestionLabelContainer,
  PaginationContainer,
  NoLineDivider,
  HeaderLabel,
  HeaderTitle,
  Container,
  Selector,
  LabelContainer,
  Label,
  ButtonContainer,
  Inputor
} from './Language.style'
import { useForm, Controller } from 'react-hook-form'
import { Button } from '@components/atoms'
import { useAsync } from '@core/hooks'
import API from '@core/api'

const langugaeOptions = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Thai',
    value: 'th'
  },
  {
    label: 'Indonesia',
    value: 'id'
  },
  {
    label: 'Vietnamese',
    value: 'vn'
  },
  {
    label: 'Chinese',
    value: 'tc'
  }
]

const InstructionLanguage = forwardRef(({ detail, type }, ref) => {
  const [allDetail, setAlldetail] = useState(detail)
  const [question, setQuestion] = useState(detail[0])
  const [language, setLanguage] = useState('en')
  const [activePage, setActivePage] = useState(1)
  const { t } = useTranslation()
  const { reset, handleSubmit, control, watch, getValues } = useForm({
    defaultValues: {
      data: detail[0].data[language]
    }
  })
  const { execute: saveTranslation } = useAsync(API.commons.language.save, {})

  useEffect(() => {
    setAlldetail(detail)
  }, [detail])

  const _HandleSelectLanguage = (value) => {
    _HandleSave()
    setLanguage(value)
  }

  useEffect(() => {
    reset({
      data: question.data[language]
    })
  }, [language])

  useEffect(() => {
    reset({
      data: allDetail[activePage - 1].data[language]
    })
  }, [activePage])

  const onChangePage = (value) => {
    _HandleSave()
    setQuestion(allDetail[value - 1])

    setActivePage(value)
  }

  const _OnSave = (data) => {
    saveTranslation({
      type: type,
      languages: [
        {
          payload: question.payload,
          data: {
            ...question.data,
            [language]: data.data
          }
        }
      ]
    })
    const newAllDetail = allDetail
    const index = allDetail.findIndex((item) => item.id === question.id)
    if (index > -1) {
      newAllDetail[index].data[language] = data.data
    }
    setAlldetail([...newAllDetail])
  }

  const _HandleSave = handleSubmit((data) => {
    _OnSave(data)
  })

  useImperativeHandle(ref, () => ({
    saveTranslation: () => {
      const languages = allDetail.map((item) => ({
        payload: item.payload,
        data: item.data
      }))
      const currentData = getValues()
      const index = languages.findIndex(
        (item) => item.payload.id === question.payload.id
      )
      if (index > -1) {
        languages[index] = {
          payload: question.payload,
          data: {
            ...question.data,
            [language]: currentData.data
          }
        }
      }

      saveTranslation({
        type: type,
        languages: languages
      })
    }
  }))

  return (
    <Container id="instruction-language" data-testid="instruction-language">
      <RowContainer
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          borderBottom: `2px solid ${theme.colors.LIGHT_GRAY}`
        }}
      >
        <ColContainer>
          <HeaderLabel>{t('language.language')}</HeaderLabel>
          <HeaderTitle>{t('language.english')}</HeaderTitle>
        </ColContainer>
        <NoLineDivider />
        <ColContainer>
          <HeaderLabel>{t('language.language')}</HeaderLabel>
          <Selector
            name="group"
            searchable={false}
            data={langugaeOptions}
            value={language}
            onSelect={_HandleSelectLanguage}
            style={{ width: 185 }}
          />
        </ColContainer>
      </RowContainer>
      <RowContainer>
        <ColContainer>
          <Modal.Body style={{ height: 400 }}>
            <QuestionLabelContainer>
              <QuestionLabel>{question.label}</QuestionLabel>
            </QuestionLabelContainer>
            <LabelContainer>
              <Label>{question.data.en}</Label>
            </LabelContainer>
          </Modal.Body>
        </ColContainer>
        <LineDivider />
        <ColContainer>
          <Modal.Body style={{ height: 400 }}>
            <QuestionLabelContainer>
              <QuestionLabel>{question.label}</QuestionLabel>
            </QuestionLabelContainer>
            <Controller
              control={control}
              name={`data`}
              defaultValue={watch('data')}
              render={({ field }) => (
                <Inputor {...field} componentClass="textarea" rows={5} />
              )}
            />
          </Modal.Body>
        </ColContainer>
      </RowContainer>
      <RowContainer style={{ paddingTop: 20, justifyContent: 'flex-end' }}>
        <PaginationContainer>
          <ButtonContainer>
            <Button
              onClick={_HandleSave}
              id="language-save-button"
              style={{ padding: '0 60px' }}
            >
              {t('language.save')}
            </Button>
          </ButtonContainer>
          <Pagination
            prev
            last
            next
            first
            size="sm"
            pages={detail.length}
            maxButtons={7}
            activePage={activePage}
            onSelect={onChangePage}
            style={{ fontWeight: 'bolder' }}
          />
        </PaginationContainer>
      </RowContainer>
    </Container>
  )
})

export default InstructionLanguage
