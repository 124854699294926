import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DescriptionRedText,
  DetailContainer,
  TopDetailContainer,
  DetailItemContainer,
  Title,
  Description
} from './Question.style'

const ResultDisplay = ({ data }) => {
  const { t } = useTranslation()

  return (
    <DetailContainer id="result-display-container">
      <TopDetailContainer id="judgement-result">
        <Title>{t('report.judgement-result')} : </Title>
        <DescriptionRedText>
          {data.diagnosisResult ? data.diagnosisResult : '-'}
        </DescriptionRedText>
      </TopDetailContainer>
      <DetailItemContainer id="repair-instructions">
        <Title>{t('report.repair-instructions')} :</Title>
        <Description>
          {data.repairInstruction ? data.repairInstruction : '-'}
        </Description>
      </DetailItemContainer>
      <DetailItemContainer id="reasons">
        <Title>{t('report.reasons')} :</Title>
        <Description>
          {data.technicianFeedback ? data.technicianFeedback : '-'}
        </Description>
      </DetailItemContainer>
      <DetailItemContainer id="repair-method">
        <Title>{t('report.repair-method')} :</Title>
        <Description>
          {data.technicianRepairMethod ? data.technicianRepairMethod : '-'}
        </Description>
      </DetailItemContainer>
    </DetailContainer>
  )
}

export default ResultDisplay
