import React from 'react'
import { Table } from 'rsuite'
import { DynamicTable } from '@components/molecules/Tables'
import { noop } from 'lodash'
import { NoLabel } from './NonSelectTable.style'
import { useTranslation } from 'react-i18next'
const { Column, HeaderCell, Cell } = Table

const NonSelectTable = React.forwardRef(
  (
    {
      rowKey = '_id',
      data = [],
      columns: cols,
      onSelect = noop,
      colProps,
      setLanguageDetail,
      handleLanguageModalOpen,
      ...props
    },
    ref
  ) => {
    const savedOnSelect = React.useRef(onSelect)
    const refTable = React.useRef()
    const { t } = useTranslation()
    const columns = React.useMemo(
      () => (Array.isArray(cols) ? [[rowKey, rowKey], ...cols] : cols),
      [cols]
    )

    const mapper = React.useCallback(
      ([key, label], index) => {
        const _colProps = {
          align: 'center',
          key,
          ...colProps
        }

        if (key === 'name') {
          return (
            <Column
              align="center"
              verticalAlign="middle"
              key={key}
              width={200}
              fixed
            >
              <HeaderCell>{label}</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <span
                      style={{
                        color: '#0058FF',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setLanguageDetail(rowData)
                        handleLanguageModalOpen()
                      }}
                    >
                      {rowData[key]}
                    </span>
                  )
                }}
              </Cell>
            </Column>
          )
        }

        return !index ? (
          <Column fixed width={70} verticalAlign="middle" key={key}>
            <HeaderCell style={{ padding: 0, textAlign: 'center' }}>
              {t('t.no')}
            </HeaderCell>
            <Cell>
              {(k, i) => {
                return <NoLabel key={k}>{i + 1}</NoLabel>
              }}
            </Cell>
          </Column>
        ) : (
          <Column
            {..._colProps}
            verticalAlign="middle"
            flexGrow={columns.length > 9 ? undefined : 1}
          >
            <HeaderCell>{label}</HeaderCell>
            <Cell dataKey={key} />
          </Column>
        )
      },
      [colProps, data.length]
    )

    React.useEffect(() => {
      savedOnSelect.current = onSelect
    }, [onSelect])

    React.useImperativeHandle(
      ref,
      () => ({
        ...refTable.current
      }),
      []
    )

    return (
      <DynamicTable
        {...props}
        columns={columns}
        data={data}
        mapper={mapper}
        ref={refTable}
      />
    )
  }
)
export default NonSelectTable
