import { Checkbox as BaseCheckbox, Table } from 'rsuite'
import styled from '@emotion/styled'

const { HeaderCell } = Table

export const Checkbox = styled(BaseCheckbox)`
  &.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  &.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: black !important;
    background-color: black !important;
  }

  .rs-checkbox-checker .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: black !important;
  }
`

export const HeaderCellCustom = styled(HeaderCell)`
  .rs-table-cell .rs-table-cell-last {
  }
  .rs-table-cell-content {
    white-space: pre;
  }
`
