import React from 'react'
import { LineDividerPopover } from '@components/molecules/Report/Report.style'
import { Popover } from 'rsuite'

const PopoverContent = React.forwardRef(
  ({ handleClick, children, ...rest }, ref) => {
    return (
      <Popover ref={ref} {...rest}>
        <div style={{ display: 'flex' }}>
          <div
            id="delete-button"
            onClick={handleClick}
            style={{
              textDecoration: 'underline',
              width: '36px',
              cursor: 'pointer',
              textAlign: 'center'
            }}
          >
            ลบ
          </div>
          <LineDividerPopover />
          <div
            id="cancel-button"
            onClick={handleClick}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              width: '36px',
              textAlign: 'center'
            }}
          >
            ยกเลิก
          </div>
        </div>
      </Popover>
    )
  }
)

export default PopoverContent
