import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLanguage } from '@configs/storage'
import locales, { SUPPORT_LANGUAGES } from '@locales'

i18n.use(initReactI18next).init({
  resources: SUPPORT_LANGUAGES.reduce(
    (result, ln) => ({
      ...result,
      [ln]: { translation: locales[ln] }
    }),
    {}
  ),
  fallbackLng: SUPPORT_LANGUAGES[0],
  lng: getLanguage(),
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  },
  keySeparator: '.'
})

export default i18n
