import { theme } from '@core/styles'
import styled from '@emotion/styled'

export const BoxContainer = styled.div``

export const QuestionContainer = styled.div`
  margin: 20px 0px;
`

export const AnswerContainer = styled.div``

export const AnswerLabel = styled.p`
  font-size: 16px;
  color: ${theme.colors.SELECTED_BLUE};
`

export const ChoiseContainer = styled.div``

export const ChoiseLabelContainer = styled.div`
  display: flex;
  align-items: center;
`
export const ChoiseLabel = styled.p`
  font-size: 16px;
  color: #0a1032;
`

export const OptionLabel = styled.p`
  font-size: 14px;
  font-weight: lighter;
  margin-left: 40px;
  color: ${theme.colors.SELECTED_BLUE};
`
export const QuestionLabel = styled.p`
  font-size: 18px;
  font-weight: 200;
  color: #0a1032;
  white-space: pre-wrap;
`
export const Title = styled.h5``

export const DetailContainer = styled.div`
  margin-top: 20px;
`

export const TopDetailContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`

export const Description = styled.p`
  font-size: 16px;
  white-space: pre-wrap;
`
export const DescriptionRedText = styled.p`
  font-size: 16px;
  color: ${theme.colors.RED_RIBBON};
  margin-left: 5px;
  font-weight: bold;
`

export const WheelLabel = styled.p`
  font-weiht: bold;
`

export const DetailItemContainer = styled.div`
  margin-bottom: 20px;
`
