import React from 'react'
import { css, Global } from '@emotion/react'

import { reset, typography, colors } from './global'
import { breakpoints } from './helpers'

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        ${reset}
        ${typography}
      `}
    />
  )
}

const theme = {
  ...colors,
  breakpoints: Object.keys(breakpoints).map((key) => breakpoints[key])
}

export { theme }
