import { theme } from '@core/styles'
import styled from '@emotion/styled'
import { Input, SelectPicker } from 'rsuite'

export const Container = styled.div``

export const LineDivider = styled.div`
  height: auto;
  border: 1px solid ${theme.colors.LIGHT_GRAY};
  margin: 0 20px;
`
export const NoLineDivider = styled.div`
  height: auto;
  border: 0px solid ${theme.colors.LIGHT_GRAY};
  margin: 0 20px;
`

export const QuestionLabelContainer = styled.div`
  margin: 10px 0px;
`

export const QuestionLabel = styled.h4`
  font-size: 20px;
  font-weight: bold;
`

export const LabelContainer = styled.div`
  background-color: #f3f4f6;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0px;
  min-height: 130px;
`

export const Label = styled.p`
  font-size: 16px;
`
export const AnswerContainer = styled.div`
  margin: 10px 0px;
  padding: 10px;
  border-radius: 10px;
`

export const Answer = styled.p`
  font-size: 16px;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const HeaderLabel = styled.p`
  font-size: 14px;
  color: #0a1032;
  margin-bottom: 20px;
`

export const HeaderTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`

export const RowContainer = styled.div`
  display: flex;
`

export const ColContainer = styled.div`
  flex: 1;
  position: relative;
`

export const Selector = styled(SelectPicker)`
  .rs-picker-toggle {
    background: #f3f4f6 !important;
  }
`

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`

export const Inputor = styled(Input)`
  background: #f3f4f6 !important;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0px;
  font-size: 16px;
`
