import styled from '@emotion/styled'
import { Form as BaseForm, ControlLabel as BaseControlLabel } from 'rsuite'

export const StyledForm = styled(BaseForm)`
  .rs-form-control-wrapper {
    .rs-input,
    .rs-picker-toggle {
      background: #f3f4f6 !important;
    }
  }

  .rs-form-group {
    margin-right: 30px;
    :last-of-type {
      margin-bottom: 24px;
    }
  }
`

export const Label = styled(BaseControlLabel)`
  &[data-required='true'] {
    ::after {
      content: '*';
      color: red;
      padding-top: 0.5em;
      margin-left: 0.24em;
    }
  }
`
