import React from 'react'
import { Whisper } from 'rsuite'
import { BaseTooltip } from './Tooltip.style'

const Tooltip = ({
  className,
  placement = 'auto',
  label = <div />,
  children = <div />,
  ...props
}) => {
  const content = React.useMemo(() => {
    if (typeof children === 'string') {
      return <div>{children}</div>
    }

    return children
  }, [children])

  return (
    <Whisper
      className={className}
      placement={placement}
      speaker={<BaseTooltip>{label}</BaseTooltip>}
      {...props}
    >
      {content}
    </Whisper>
  )
}

export default Tooltip
