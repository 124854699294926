import { useState, useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '@core/contexts'

import { Alert } from 'rsuite'

import { noop } from 'lodash'

const defaultErrorMessage = 'Something went wrong, Please try again.'

export default (
  promise,
  {
    immediate = false,
    setLoading = noop,
    syncLoading = false,
    onSuccess = () => {}, // create once on mounted
    onFailed = () => {}, // create once on mounted
    onFinally = () => {}, // create once on mounted
    debug = false
  } = {}
) => {
  const { t } = useTranslation()
  const [, { revokeUser }] = useStore()

  const [data, setValue] = useState(null)
  const [error, setError] = useState(null)
  const [pending, setPending] = useState(false)

  const reset = useCallback(() => {
    setPending(false)
    setValue(null)
    setError(null)
  }, [])

  const _onSuccess = useRef(onSuccess)
  const _onFailed = useRef(onFailed)
  const _onFinally = useRef(onFinally)
  const _savedPromiseFunction = useRef(promise)

  const _setLoadState = useCallback((bool) => {
    setLoading(bool)
    setPending(bool)
  }, [])

  const execute = useCallback(
    (args, callback = {}) => {
      _setLoadState(true)

      // Note: IE(old) browser not support async/await feature you can use only promise/then
      _savedPromiseFunction
        .current(args)
        .then((res) => {
          callback.onSuccess && callback.onSuccess(res, args)
          const i = _onSuccess.current(res, args)

          if (typeof i === 'object') setValue(i)
          else setValue(res)
        })
        .catch((error) => {
          callback.onFailed && callback.onFailed(error)
          _onFailed.current(error, args)

          const httpStatus = error?.status
          const responseCode = error?.data?.responseCode

          const isUnauthorize = [401].includes(httpStatus)

          if (isUnauthorize) {
            revokeUser()
            return
          }

          const msgI18 = `errors.backend.${responseCode}`
          let message = t(msgI18) || error?.data?.message

          if (String(message) === String(msgI18)) {
            message = defaultErrorMessage
          }

          Alert.error(message)
          setError(error)
          setValue(null)
        })
        .finally(() => {
          callback.onFinally && callback.onFinally()
          _onFinally.current()
          _setLoadState(false)
        })
    },
    [_setLoadState, revokeUser, t]
  )

  useEffect(() => {
    if (immediate) execute()
  }, [])

  return { execute, pending, data, error, reset }
}
