export default {
  colors: {
    WHITE: '#fff',
    BLACK: '#000',
    ATHENS_GRAY: '#f3f4f6',
    LOBLOLLY: '#b8bfc9',
    RED_RIBBON: '#eb0a1e',
    LIGHT_GRAY: '#dedede',
    SELECTED_BLUE: '#4F8EF7'
  }
}
