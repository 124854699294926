import React from 'react'
import Popover from '@components/molecules/Popover/Popover'
import Trash from '@rsuite/icons/Trash'

const TrashIcon = ({ onClick, renderFlag }) => {
  return (
    <Popover
      trigger="click"
      placement="bottomEnd"
      isCustomize
      onClick={onClick}
      renderFlag={renderFlag}
    >
      <Trash
        style={{
          marginLeft: 'auto',
          color: 'red',
          position: 'absolute',
          cursor: 'pointer',
          right: '12px',
          top: '12px'
        }}
      />
    </Popover>
  )
}

export default TrashIcon
