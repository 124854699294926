import React from 'react'
import { Sidenav, Nav } from './Navigator.style'
import { ToyotaLogo } from '@assets/image/js'
import { Link } from 'react-router-dom'

const Navigator = ({ menu = [], activeKey, ...props }) => {
  return (
    <Sidenav expanded={false} activeKey={activeKey} {...props}>
      <Sidenav.Header className="center">
        <ToyotaLogo />
      </Sidenav.Header>
      <div style={{ height: '18vh' }} />
      <Sidenav.Body>
        <Nav>
          {menu?.map(({ key, label, icon, link, ...item }) => (
            <Nav.Item
              componentClass={Link}
              to={link}
              key={key}
              eventKey={key}
              icon={icon}
              {...item}
            >
              {label}
            </Nav.Item>
          ))}
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  )
}

export default Navigator
