import React from 'react'

export default ({ width = '38.231', height = '25.966', fill = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38.231 25.966"
    >
      <path
        fill={fill}
        d="M329.457,224.64c-10.795,0-19.117,5.5-19.117,12.984s8.322,12.982,19.117,12.982,19.114-5.5,19.114-12.979S340.252,224.64,329.457,224.64Zm11.682,6.114c0,1.717-2.746,3.046-7.148,3.577-.413-4.111-1.6-6.816-3.126-7.827C335.558,226.8,341.139,228.462,341.139,230.754Zm-11.682,13.877c-1.529,0-2.78-2.385-2.878-6.989q1.377.113,2.878.114c1,0,1.96-.039,2.878-.114C332.237,242.246,330.986,244.631,329.457,244.631Zm0-10.057q-1.413,0-2.69-.083c.418-2.754,1.462-5.018,2.69-5.018s2.272,2.264,2.69,5.018q-1.264.09-2.69.093Zm-1.411-8.072c-1.529,1.008-2.718,3.716-3.131,7.829-4.4-.531-7.149-1.859-7.149-3.577C317.775,228.454,323.355,226.8,328.046,226.5Zm-14.459,10.609a8.394,8.394,0,0,1,1.671-5.042c.425,2.465,4.02,4.59,9.511,5.369v.188c0,6.006,1.4,9.879,3.319,11.1C319.964,248.213,313.587,243.182,313.587,237.111Zm17.239,11.6c1.921-1.222,3.319-5.1,3.319-11.1v-.183c5.493-.774,9.085-2.9,9.513-5.369a8.394,8.394,0,0,1,1.666,5.049C345.324,243.182,338.949,248.213,330.826,248.716Z"
        transform="translate(-310.34 -224.64)"
      />
    </svg>
  )
}
