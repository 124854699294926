import React from 'react'
const LanguageIcon = React.forwardRef((props, ref) => (
  <svg
    {...props}
    ref={ref}
    id="Component_167_12"
    data-name="Component 167 – 12"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g id="outline-dashboard-24px">
      <path
        id="Path_11385"
        data-name="Path 11385"
        d="M0,0H32V32H0Z"
        fill="none"
      />
      <path
        id="Icon_awesome-language"
        data-name="Icon awesome-language"
        d="M7.605,13.76c-.175-.6-.39-1.66-.39-1.66H7.19s-.215,1.055-.39,1.66l-.555,1.875h1.9ZM30.8,6.75h-14v16h14a1.2,1.2,0,0,0,1.2-1.2V7.95A1.2,1.2,0,0,0,30.8,6.75Zm-1.2,6a.6.6,0,0,1-.6.6h-.57a8.959,8.959,0,0,1-2.135,3.5,15.5,15.5,0,0,0,1.3.9.6.6,0,0,1,.2.81l-.4.695a.6.6,0,0,1-.835.215,17.4,17.4,0,0,1-1.77-1.245,17.633,17.633,0,0,1-1.77,1.245.6.6,0,0,1-.835-.215l-.4-.695a.6.6,0,0,1,.21-.81,14.263,14.263,0,0,0,1.3-.9,11.6,11.6,0,0,1-1.05-1.285.6.6,0,0,1,.185-.855l.325-.2.365-.215a.6.6,0,0,1,.8.17,9.534,9.534,0,0,0,.87,1.045,7.648,7.648,0,0,0,1.5-2.16H20.6a.6.6,0,0,1-.6-.6v-.8a.6.6,0,0,1,.6-.6h3.2v-.8a.6.6,0,0,1,.6-.6h.8a.6.6,0,0,1,.6.6v.8H29a.6.6,0,0,1,.6.6ZM0,7.95v13.6a1.2,1.2,0,0,0,1.2,1.2h14v-16H1.2A1.2,1.2,0,0,0,0,7.95ZM2.945,18.755,5.82,10.3a.6.6,0,0,1,.57-.4H8.015a.609.609,0,0,1,.57.4l2.875,8.455a.6.6,0,0,1-.57.8H9.745a.6.6,0,0,1-.575-.43L8.7,17.525H5.69l-.455,1.59a.6.6,0,0,1-.575.435H3.515a.6.6,0,0,1-.57-.8Z"
        transform="translate(0 1.25)"
        fill="#fff"
      />
    </g>
  </svg>
))

export default LanguageIcon
