import { theme } from '@core/styles'
import styled from '@emotion/styled'

export const ImagePreviewBox = styled.div`
  background-color: #f3f4f6;
  height: 400px;
  width: 100%;
  border-radius: 10px;
`

export const ThumbContainer = styled.div`
  position: relative;
`

export const ThumbImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`

export const LabelContainer = styled.div`
  position: absolute;
  bottom: 10px;
  text-align: center;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  color: ${theme.colors.WHITE};
  font-size: 12px;
`
export const ImagePreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const ImagePreviewTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
`

export const ToggleLabel = styled.label`
  font-size: 18px;
  font-weight: 800;
`
