import React from 'react'
// import { useTranslation } from 'react-i18next'
import {
  BoxContainer,
  QuestionContainer,
  AnswerContainer,
  ChoiseContainer,
  ChoiseLabelContainer,
  ChoiseLabel,
  OptionLabel,
  QuestionLabel
} from './Question.style'
import { Radio } from 'rsuite'

const SingleChoiseQuestion = ({ data }) => {
  // const { t } = useTranslation()

  return (
    <BoxContainer id="single-choise-question">
      <QuestionContainer>
        <QuestionLabel>{data.question}</QuestionLabel>
      </QuestionContainer>
      <AnswerContainer>
        {data.choices.map((item) => {
          return (
            <ChoiseContainer key={item.id}>
              <ChoiseLabelContainer>
                <Radio checked={item.value} />
                <ChoiseLabel>{item.text}</ChoiseLabel>
              </ChoiseLabelContainer>
              {item.options && (
                <OptionLabel>{item.options.additionalField.value}</OptionLabel>
              )}
            </ChoiseContainer>
          )
        })}
      </AnswerContainer>
    </BoxContainer>
  )
}

export default SingleChoiseQuestion
