import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination, Modal } from 'rsuite'
import {
  RowContainer,
  ColContainer,
  LineDivider,
  QuestionLabel,
  QuestionLabelContainer,
  PaginationContainer,
  NoFileAttachmentContainer
} from './Report.style'
import MultipleChoisesQuestion from './question/MultipleChoisesQuestion'
import SingleChoiseQuestion from './question/SingleChoiseQuestion'
import TextFieldQuestion from './question/TextFieldQuestion'
import FileAttactmentPreview from '../FileAttachmentsPreview/FileAttachmentsPreview'
import ResultDisplay from './question/ResultDisplay'
import FileAttachmentsQuestion from './question/FileAttachmentsQuestion'
import { cloneDeep } from 'lodash'

const CheckSheetReport = ({ detail }) => {
  const { t } = useTranslation()
  const [questionData, setQuestionData] = useState(
    detail.questionList[0].questionData
  )
  const [activePage, setActivePage] = useState(1)
  const [fileAttachments, setFileAttachments] = useState(
    detail.questionList[0].fileAttachments
  )

  const fileAttachmentsPreviewRef = useRef(null)

  const onChangePage = (value) => {
    if (fileAttachmentsPreviewRef.current) {
      fileAttachmentsPreviewRef.current.handleChangeIndex(0)
    }
    if (value === detail.questionList.length + 1 && detail.analysisResult) {
      setFileAttachments(detail.analysisResult.fileAttachments)
      setQuestionData({
        type: 'result',
        ...detail.analysisResult
      })
      setActivePage(value)
    } else {
      setQuestionData(detail.questionList[value - 1].questionData)
      setFileAttachments(
        cloneDeep(detail.questionList[value - 1].fileAttachments)
      )
      setActivePage(value)
    }
  }

  return (
    <RowContainer
      style={{ display: 'flex' }}
      id="absorber-checikng-report"
      data-testid="checksheet"
    >
      <ColContainer>
        <Modal.Body style={{ height: 400 }}>
          {questionData.type !== 'result' && (
            <QuestionLabelContainer>
              <QuestionLabel id="question-label">
                {t('report.question')}{' '}
                {activePage < 10 ? `0${activePage}` : activePage}
              </QuestionLabel>
            </QuestionLabelContainer>
          )}
          {questionData.type === 'text-field' && (
            <TextFieldQuestion data={questionData} />
          )}
          {questionData.type === 'single-choice' && (
            <SingleChoiseQuestion data={questionData} />
          )}
          {questionData.type === 'multiple-choices' && (
            <MultipleChoisesQuestion data={questionData} />
          )}
          {questionData.type === 'file-attachment' && (
            <FileAttachmentsQuestion data={questionData} />
          )}
          {questionData.type === 'result' && (
            <ResultDisplay data={questionData} />
          )}
        </Modal.Body>
      </ColContainer>
      <LineDivider />
      <ColContainer>
        {fileAttachments.length > 0 ? (
          <FileAttactmentPreview
            data={fileAttachments}
            id="file-attactment-preview"
            ref={fileAttachmentsPreviewRef}
          />
        ) : (
          <NoFileAttachmentContainer />
        )}
        <PaginationContainer>
          <Pagination
            prev
            last
            next
            first
            size="sm"
            pages={
              detail.analysisResult
                ? detail.questionList.length + 1
                : detail.questionList.length
            }
            maxButtons={7}
            activePage={activePage}
            onSelect={onChangePage}
            style={{ fontWeight: 'bolder' }}
          />
        </PaginationContainer>
      </ColContainer>
    </RowContainer>
  )
}

export default CheckSheetReport
