import React from 'react'

const VideoPlayer = ({ url }) => {
  return (
    // <video
    //   style={{ maxHeight: 'calc(100% - 50px)', maxWidth: '100%' }}
    //   controls
    // >
    //   <source src={url} type="video/mp4" />
    //   <source src="movie.ogg" type="video/ogg" />
    //   Your browser does not support the video tag.
    // </video>
    <video
      style={{ maxHeight: 'calc(100% - 50px)', maxWidth: '100%' }}
      controls
    >
      <source src={url} />
      Sorry, your browser does not support embedded videos.
    </video>
  )
}

export default VideoPlayer
