import axios from '@configs/axios'

const generateApi =
  (axi) =>
  (path = '') =>
  (method = '', id = '') =>
  (data = {}, options = {}) => {
    return ['get', 'delete'].includes(method.toLowerCase())
      ? axi[method](`${path}/${id}`, { params: data, ...options })
      : axi[method](`${path}/${id}`, data, options)
  }

const createRequest = generateApi(axios)

export default (() => ({
  auth: createRequest('/auth'),
  common: createRequest('/common'),
  diagnosis: createRequest('/diagnosis')
}))()
