import React from 'react'

export default ({ fill = '#eb0a1e', stroke = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="249.896"
      height="258.596"
      viewBox="0 0 249.896 258.596"
    >
      <defs>
        <filter
          id="Subtraction_1"
          x="2.345"
          y="2.345"
          width="245.206"
          height="253.906"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" onInput="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.322" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Subtraction_2"
          x="0"
          y="0"
          width="249.896"
          height="258.596"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" onInput="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-2" />
          <feFlood floodOpacity="0.322" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_2857"
        data-name="Group 2857"
        transform="translate(-558.052 -183.347)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 558.05, 183.35)"
          filter="url(#Subtraction_1)"
        >
          <path
            id="Subtraction_1-2"
            data-name="Subtraction 1"
            d="M90.5,203H25.375A25.355,25.355,0,0,1,0,177.625V25.375A25.354,25.354,0,0,1,25.375,0H90.5a102.25,102.25,0,0,1,20.456,2.062A100.942,100.942,0,0,1,147.25,17.335a101.8,101.8,0,0,1,36.774,44.657,100.989,100.989,0,0,1,5.914,19.053,102.491,102.491,0,0,1,0,40.912,100.945,100.945,0,0,1-15.272,36.294,101.8,101.8,0,0,1-44.657,36.774,100.988,100.988,0,0,1-19.052,5.914A102.247,102.247,0,0,1,90.5,203ZM54.229,70.852l46.314,46.378-2.783,2.786a4.577,4.577,0,0,0,0,6.46l42.726,42.784a14.511,14.511,0,0,0,20.508-20.536l-42.726-42.783a4.556,4.556,0,0,0-6.451,0l-2.6,2.6L62.9,62.167,65.067,60a4.572,4.572,0,0,0-.52-6.907l-18.9-13.936a4.557,4.557,0,0,0-5.931.448L31.7,47.638a4.574,4.574,0,0,0-.448,5.939L45.166,72.5a4.557,4.557,0,0,0,6.9.521l2.164-2.167h0Zm23.805,34.26L34.48,148.724A14.511,14.511,0,1,0,54.988,169.26l37.545-37.6a11.831,11.831,0,0,1-3.352-6.777,12.067,12.067,0,0,1,1.307-7.3L78.034,105.112Zm37.009-7.88a11.835,11.835,0,0,1,8.406,3.475l4.842-4.848a34.105,34.105,0,0,0,43.848-22A34.489,34.489,0,0,0,173.25,58.14a3.105,3.105,0,0,0-5.262-1.7l-7.189,7.2A15,15,0,0,1,139.6,42.4l7.189-7.2a3.111,3.111,0,0,0-1.7-5.27,34.351,34.351,0,0,0-5.5-.444,34.145,34.145,0,0,0-31.972,46L97.116,86,109.625,98.53A11.771,11.771,0,0,1,115.043,97.232Z"
            transform="translate(50.08 8.34) rotate(11)"
            fill={fill}
          />
        </g>
        <g
          transform="matrix(1, 0, 0, 1, 558.05, 183.35)"
          filter="url(#Subtraction_2)"
        >
          <path
            id="Subtraction_2-2"
            data-name="Subtraction 2"
            d="M90.5,203H25.375A25.355,25.355,0,0,1,0,177.625V25.375A25.354,25.354,0,0,1,25.375,0H90.5a102.25,102.25,0,0,1,20.456,2.062A100.942,100.942,0,0,1,147.25,17.335a101.8,101.8,0,0,1,36.774,44.657,100.989,100.989,0,0,1,5.914,19.053,102.491,102.491,0,0,1,0,40.912,100.945,100.945,0,0,1-15.272,36.294,101.8,101.8,0,0,1-44.657,36.774,100.988,100.988,0,0,1-19.052,5.914A102.247,102.247,0,0,1,90.5,203ZM54.229,70.852l46.314,46.378-2.783,2.786a4.577,4.577,0,0,0,0,6.46l42.726,42.784a14.511,14.511,0,0,0,20.508-20.536l-42.726-42.783a4.556,4.556,0,0,0-6.451,0l-2.6,2.6L62.9,62.167,65.067,60a4.572,4.572,0,0,0-.52-6.907l-18.9-13.936a4.557,4.557,0,0,0-5.931.448L31.7,47.638a4.574,4.574,0,0,0-.448,5.939L45.166,72.5a4.557,4.557,0,0,0,6.9.521l2.164-2.167h0Zm23.805,34.26L34.48,148.724A14.511,14.511,0,1,0,54.988,169.26l37.545-37.6a11.831,11.831,0,0,1-3.352-6.777,12.067,12.067,0,0,1,1.307-7.3L78.034,105.112Zm37.009-7.88a11.835,11.835,0,0,1,8.406,3.475l4.842-4.848a34.105,34.105,0,0,0,43.848-22A34.489,34.489,0,0,0,173.25,58.14a3.105,3.105,0,0,0-5.262-1.7l-7.189,7.2A15,15,0,0,1,139.6,42.4l7.189-7.2a3.111,3.111,0,0,0-1.7-5.27,34.351,34.351,0,0,0-5.5-.444,34.145,34.145,0,0,0-31.972,46L97.116,86,109.625,98.53A11.771,11.771,0,0,1,115.043,97.232Z"
            transform="translate(50.08 8.34) rotate(11)"
            fill={fill}
            stroke={stroke}
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  )
}
