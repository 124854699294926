import request from './request'

export default (() => ({
  getTechnicalDesignGroupMaster() {
    return request.diagnosis(
      'get',
      'photo/web/shockAbsorber/technicalDesignGroupMaster'
    )()
  },
  // PATH CAN BE CHANGED
  // comment: {
  //   get({ id, part, side }) {
  //     return request.diagnosis(
  //       'get',
  //       `/comment/?submissionId=${id}&part=${part}&side=${side}`
  //     )()
  //   },
  //   patch({
  //     submissionId,
  //     part,
  //     side,
  //     technicalDesignGroup,
  //     technicalDesignComment
  //   }) {
  //     return request.diagnosis(
  //       'patch',
  //       `comment`
  //     )({
  //       submissionId,
  //       part,
  //       side,
  //       technicalDesignGroup,
  //       technicalDesignComment
  //     })
  //   },
  //   delete(id) {
  //     return request.diagnosis('delete', `comment?commentId=${id}`)()
  //   }
  // },
  judgement: {
    get() {
      return request.diagnosis('get', 'judgement')()
    },
    patch({ submissionId, part, side, judgementDesign }) {
      return request.diagnosis(
        'patch',
        'judgement'
      )({
        submissionId,
        part,
        side,
        judgementDesign
      })
    }
  }
}))()
