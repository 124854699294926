import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AudioContainer,
  DetailContainer,
  DetailItemContainer,
  TopDetailContainer,
  DescriptionRedText,
  Description,
  Title,
  VehicleDetail,
  RowContainer,
  LineDivider,
  PaginationContainer,
  PhotoColContainer,
  ColContainer,
  NoFileAttachmentContainer
} from './Report.style'
import { Pagination } from 'rsuite'
import ImagePreviewForm from '../ImagePreviewForm/ImagePreviewForm'
import { checkFileType } from '@core/utils/file-format'
import FileAttachmentsPreview from '../FileAttachmentsPreview/FileAttachmentsPreview'

const NoiseReport = ({
  detail,
  model,
  katashiki,
  commentList,
  handleCreateComment,
  handleRemove,
  renderFlag,
  designGroupOption,
  formValue,
  setFormValue,
  handleChangeJudgement,
  loadingComment,
  loadingFrom
}) => {
  const { t } = useTranslation()
  const isDelaySubmission = detail.isDelaySubmission

  const [activePage, setActivePage] = useState(1)
  const [fileAttachments, setFileAttachments] = useState([
    detail.delaySubmissionFileList[0]
  ])
  const fileAttachmentsPreviewRef = useRef(null)

  const onChangePage = (value) => {
    if (fileAttachmentsPreviewRef.current) {
      fileAttachmentsPreviewRef.current.handleChangeIndex(0)
    }
    setFileAttachments([detail.delaySubmissionFileList[value - 1]])
    setActivePage(value)
  }

  return (
    <RowContainer
      style={{ display: 'flex', justifyContent: 'space-between' }}
      id="noise-report"
      data-testid="noise"
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          minHeight: 600
        }}
      >
        <VehicleDetail id="vehicle-detail">
          <article>
            <span className="text-select-blue">
              {t('page-search.table.vinNo')}:
            </span>
            <span>{detail.vinNo}</span>
          </article>
          <article>
            <span className="text-select-blue">
              {t('page-search.table.model')}:
            </span>
            <span>{model}</span>
          </article>
          <article>
            <span className="text-select-blue">
              {t('page-search.table.katashiki')}:
            </span>
            <span>{katashiki}</span>
          </article>
        </VehicleDetail>
        {!isDelaySubmission && (
          <AudioContainer id="audio-container">
            <audio controls src={detail.audioFileS3Key} id="audio-element">
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </AudioContainer>
        )}
        <DetailContainer id="detail-container">
          <TopDetailContainer id="judgement-result">
            <Title>{t('report.judgement-result')} : </Title>
            <DescriptionRedText>
              {detail.diagnosisResult ? detail.diagnosisResult : '-'}
            </DescriptionRedText>
          </TopDetailContainer>

          <DetailItemContainer id="repair-instructions">
            <Title>{t('report.repair-instructions')} :</Title>
            <Description>
              {detail.repairInstruction ? detail.repairInstruction : '-'}
            </Description>
          </DetailItemContainer>

          {detail.part !== 'turbo-charger' && (
            <DetailItemContainer id="technician-judgement-result">
              <Title>{t('report.technician-judgement-result')} :</Title>
              <Description>
                {detail.technicianJudgementResult
                  ? detail.technicianJudgementResult
                  : '-'}
              </Description>
            </DetailItemContainer>
          )}

          <DetailItemContainer id="reasons">
            <Title>{t('report.reasons')} :</Title>
            <Description>
              {detail.technicianFeedback ? detail.technicianFeedback : '-'}
            </Description>
          </DetailItemContainer>

          <DetailItemContainer id="repair-method">
            <Title>{t('report.repair-method')} :</Title>
            <Description>
              {detail.technicianRepairMethod
                ? detail.technicianRepairMethod
                : '-'}
            </Description>
          </DetailItemContainer>
        </DetailContainer>
      </div>
      <LineDivider />
      {isDelaySubmission ? (
        <>
          <ColContainer>
            {detail.delaySubmissionFileList.length > 0 ? (
              <FileAttachmentsPreview
                data={fileAttachments.map((item) => ({
                  name: item.label,
                  type: checkFileType(item.url),
                  presignedUrl: item.url
                }))}
                id="file-attactment-preview"
                ref={fileAttachmentsPreviewRef}
              />
            ) : (
              <NoFileAttachmentContainer />
            )}
            <PaginationContainer>
              <Pagination
                prev
                last
                next
                first
                size="sm"
                pages={detail.delaySubmissionFileList.length}
                maxButtons={7}
                activePage={activePage}
                onSelect={onChangePage}
                style={{ fontWeight: 'bolder' }}
              />
            </PaginationContainer>
          </ColContainer>
        </>
      ) : (
        <PhotoColContainer
          style={{
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
            minHeight: 600
          }}
        >
          <ImagePreviewForm
            commentList={commentList}
            handleCreateComment={handleCreateComment}
            handleRemove={handleRemove}
            renderFlag={renderFlag}
            designGroupOption={designGroupOption}
            formValue={formValue}
            setFormValue={setFormValue}
            handleChangeJudgement={handleChangeJudgement}
            loadingComment={loadingComment}
            loadingFrom={loadingFrom}
          />
        </PhotoColContainer>
      )}
    </RowContainer>
  )
}

export default NoiseReport
