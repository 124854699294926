import CryptoJS from 'crypto-js'
import { STORAGE_SECRET_KEY } from '@configs/env'

export const STORAGE_KEYS = {
  USER_KEY: 'quia molestias aperiam',
  TOKEN_KEY: 'facilis in sequi',
  LANGUAGE_KEY: 'odit rerum aliquam'
}

const createStorage = (storage, secret) => {
  return {
    get: (key) => {
      try {
        const cipherText = storage.getItem(key)
        const bytes = CryptoJS.AES.decrypt(cipherText, secret)
        const stringy = bytes.toString(CryptoJS.enc.Utf8)

        return JSON.parse(stringy)
      } catch {
        return null
      }
    },
    set: (key, value) => {
      try {
        const stringy = JSON.stringify(value)
        const cipherText = CryptoJS.AES.encrypt(stringy, secret).toString()

        storage.setItem(key, cipherText)
      } catch {
        console.log('collect data to storage failed', { key, value })
      }
    },
    remove: (key) => {
      storage.removeItem(key)
    },
    clear: () => {
      storage.clear()
    }
  }
}

const storage = createStorage(localStorage, STORAGE_SECRET_KEY)

export const getLanguage = () => storage.get(STORAGE_KEYS.LANGUAGE_KEY)
export const getUser = () => storage.get(STORAGE_KEYS.USER_KEY)
export const getToken = () => storage.get(STORAGE_KEYS.TOKEN_KEY)

export const setLanguage = (lng) => storage.set(STORAGE_KEYS.LANGUAGE_KEY, lng)
export const setUser = (user) => storage.set(STORAGE_KEYS.USER_KEY, user)
export const setToken = (token) => storage.set(STORAGE_KEYS.TOKEN_KEY, token)

export const removeUser = (user) => storage.remove(STORAGE_KEYS.USER_KEY)
export const removeToken = (token) => storage.remove(STORAGE_KEYS.TOKEN_KEY)

export default storage
