import React from 'react'
import { theme } from '@core/styles'

const styles = {
  root: {
    height: 18,
    width: 18,
    cursor: 'pointer',
    border: 0,
    background: 'none',
    padding: 0
  },
  dot: {
    backgroundColor: theme.colors.WHITE,
    height: 12,
    width: 12,
    borderRadius: 6,
    margin: 3
  },
  active: {
    backgroundColor: theme.colors.RED_RIBBON
  }
}

const PaginationDot = ({ index, active, onClick }) => {
  const handleClick = (event) => {
    onClick(event, index)
  }

  let styleDot

  if (active) {
    styleDot = Object.assign({}, styles.dot, styles.active)
  } else {
    styleDot = styles.dot
  }

  return (
    <button type="button" style={styles.root} onClick={handleClick}>
      <div style={styleDot} />
    </button>
  )
}

export default PaginationDot
