import styled from '@emotion/styled'

export const Title = styled.h3`
  font-size: 30px;
  font-weight: bold;
`

export const ModalContainer = styled.h3`
  padding: 20px;
`
