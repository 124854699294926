import _ from 'lodash'
import moment from 'moment'
import { FORMAT } from '@configs/constants'

const trimValue = (value) => {
  const result =
    typeof value === 'string'
      ? value.trim()
      : Array.isArray(value)
      ? value.map(trimValue)
      : value

  return result
}

const cleanQueryParams = (data) =>
  _.reduce(
    data,
    (result, current, key) => {
      const trimmed = trimValue(current)

      if (['', null, undefined].includes(trimmed)) return result

      if (Array.isArray(trimmed)) {
        if (_.isEmpty(trimmed)) return result

        return { ...result, [key]: trimmed.toString() }
      }

      return { ...result, [key]: trimmed }
    },
    {}
  )

const cleanBody = (data) => {
  if (data instanceof FormData) return data
  const cleaned = _.reduce(
    data,
    (result, current, key) => {
      const trimmed = trimValue(current)

      if (['', null, undefined].includes(trimmed)) return result

      return {
        ...result,
        [key]: trimmed
      }
    },
    {}
  )

  return cleaned
}

const sorter = (
  data,
  { sortColumn = '', sortType },
  origin,
  format = FORMAT.BE2FE
) => {
  if (sortColumn && sortType) {
    const isDate = sortColumn.toLowerCase().includes('date')

    return data.sort((a, b) => {
      let x = a[sortColumn]
      let y = b[sortColumn]

      if (isDate) {
        return sortType === 'asc'
          ? moment(x, format) - moment(y, format)
          : moment(y, format) - moment(x, format)
      } else {
        if (typeof x === 'string') {
          x = x.charCodeAt()
        }

        if (typeof y === 'string') {
          y = y.charCodeAt()
        }

        return sortType === 'asc' ? x - y : y - x
      }
    })
  }

  data.forEach((_, x) => {
    data[x] = origin[x]
  })

  return data
}

export { cleanQueryParams, cleanBody, sorter }
