const getTranslationGroups =
  (...supportedLanguages) =>
  (...filePrefixNames) => {
    return supportedLanguages.reduce((result, lang) => {
      return {
        ...result,
        [lang]: filePrefixNames.reduce((acc, name) => {
          const path = `./${lang}/${name}.${lang}.json`
          try {
            if (name === 'translation') {
              return {
                ...acc,
                ...require(path + '')
              }
            }

            return {
              ...acc,
              [name]: require(path + '')
            }
          } catch {
            return acc
          }
        }, {})
      }
    }, {})
  }

export const SUPPORT_LANGUAGES = ['en', 'th', 'ch', 'id', 'vi']
const createTranslationConfigs = getTranslationGroups(...SUPPORT_LANGUAGES)
export default createTranslationConfigs(
  'translation',
  'home',
  '404',
  'page-search'
)
