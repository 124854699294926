import { theme } from '@core/styles'
import React, { useMemo } from 'react'
import ImagePreview from '../ImagePreview/ImagePreview'
import ImagePreviewForm from '../ImagePreviewForm/ImagePreviewForm'
import {
  ButtonContainer,
  CarImageContainer,
  SelectedDot,
  SelectedLine,
  UnselectedDot,
  UnselectedLine,
  ButtonBox,
  PhotoDetailContainer,
  DetailItemContainer,
  TopDetailContainer,
  LineDivider,
  RowContainer,
  PhotoColContainer,
  Description,
  Title,
  DescriptionRedText,
  WheelLabel,
  VehicleDetail
} from './Report.style'
import { useTranslation } from 'react-i18next'

const PhotoReport = ({
  detail,
  model,
  katashiki,
  commentList,
  setSelectedSubmissionSide,
  handleCreateComment,
  handleRemove,
  renderFlag,
  designGroupOption,
  formValue,
  setFormValue,
  handleChangeJudgement,
  loadingComment,
  selectedSubmission,
  setSelectSubmission,
  loadingFrom,
  cameraGuideImage,
  setIndexImage,
  indexImage
}) => {
  const Car =
    'https://firebasestorage.googleapis.com/v0/b/dea-project-uat.appspot.com/o/car.png?alt=media&token=a5f45059-2549-4b51-8ed9-850bb7fb277d'
  const Selected =
    'https://firebasestorage.googleapis.com/v0/b/dea-project-uat.appspot.com/o/selected.png?alt=media&token=161c45e0-3717-431c-b02c-a38cb9f20ac5'
  const Unselected =
    'https://firebasestorage.googleapis.com/v0/b/dea-project-uat.appspot.com/o/unselected.png?alt=media&token=a1de684a-30c4-4765-825f-b296c6ebe7f8'
  const DefaultSideImage =
    'https://firebasestorage.googleapis.com/v0/b/dea-project-uat.appspot.com/o/defaut.png?alt=media&token=20bff08c-c687-480f-a2ec-904fd2626ff4'
  const { t } = useTranslation()
  const refImageCarousel = React.useRef()

  const submissions = useMemo(() => {
    if (detail?.isDelaySubmission) {
      return detail.submissions.map((item) => {
        return {
          ...item,
          side: '-'
        }
      })
    }
    return detail.submissions
  }, [detail])

  const checkSideExit = (side) => {
    const valid = submissions.find((item) => {
      return item.side.toString() === side.toString()
    })

    return valid
  }

  const handleSelectedSubmission = (side) => {
    const item = submissions.find((item1) => {
      return item1.side === side
    })
    refImageCarousel.current.moveTo(0)
    setSelectSubmission(item)
    setSelectedSubmissionSide(side)
  }

  return (
    <RowContainer
      style={{ display: 'flex', justifyContent: 'space-between' }}
      id="absorber-checikng-report"
      data-testid="photo"
    >
      <PhotoColContainer style={{ flex: 1 }}>
        <VehicleDetail id="vehicle-detail">
          <article>
            <span className="text-select-blue">
              {t('page-search.table.vinNo')}:
            </span>
            <span>{detail.vinNo}</span>
          </article>
          <article>
            <span className="text-select-blue">
              {t('page-search.table.model')}:
            </span>
            <span>{model}</span>
          </article>
          <article>
            <span className="text-select-blue">
              {t('page-search.table.katashiki')}:
            </span>
            <span>{katashiki}</span>
          </article>
        </VehicleDetail>
        <CarImageContainer id="car-image-container">
          {['fr', 'rr', 'fl', 'rl'].map((item) => {
            if (item === 'fr') {
              if (checkSideExit(item)) {
                return (
                  <ButtonContainer
                    style={{
                      top: 0,
                      left: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    onClick={() => {
                      if (checkSideExit(item)) {
                        handleSelectedSubmission(item)
                      }
                    }}
                    id={`wheel-${item}-button`}
                  >
                    {selectedSubmission?.side === item ? (
                      <ButtonBox>
                        <WheelLabel
                          style={{
                            color: theme.colors.SELECTED_BLUE,
                            fontSize: 14
                          }}
                        >
                          {t('report.fr')}
                        </WheelLabel>
                        <img src={Selected} style={{ width: 41, height: 41 }} />
                        <SelectedLine />
                        <SelectedDot />
                      </ButtonBox>
                    ) : (
                      <ButtonBox>
                        <WheelLabel
                          style={{
                            fontSize: 14
                          }}
                        >
                          {t('report.fr')}
                        </WheelLabel>
                        <img
                          src={DefaultSideImage}
                          style={{ width: 41, height: 41 }}
                        />
                        <UnselectedLine />
                        <UnselectedDot />
                      </ButtonBox>
                    )}
                  </ButtonContainer>
                )
              } else {
                return (
                  <ButtonContainer
                    style={{
                      top: 0,
                      left: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    id={`wheel-${item}-button`}
                  >
                    <ButtonBox>
                      <WheelLabel
                        style={{
                          color: theme.colors.SELECTED_BLUE,
                          fontSize: 14
                        }}
                      >
                        {t('report.fr')}
                      </WheelLabel>
                      <img src={Unselected} style={{ width: 41, height: 41 }} />
                      <UnselectedLine />
                      <UnselectedDot />
                    </ButtonBox>
                  </ButtonContainer>
                )
              }
            } else if (item === 'rr') {
              if (checkSideExit(item)) {
                return (
                  <ButtonContainer
                    style={{
                      top: 0,
                      right: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    onClick={() => {
                      if (checkSideExit(item)) {
                        handleSelectedSubmission(item)
                      }
                    }}
                    id={`wheel-${item}-button`}
                  >
                    {selectedSubmission?.side === item ? (
                      <ButtonBox>
                        <WheelLabel
                          style={{
                            color: theme.colors.SELECTED_BLUE,
                            fontSize: 14
                          }}
                        >
                          {t('report.rr')}
                        </WheelLabel>
                        <img src={Selected} style={{ width: 41, height: 41 }} />
                        <SelectedLine />
                        <SelectedDot />
                      </ButtonBox>
                    ) : (
                      <ButtonBox>
                        <WheelLabel
                          style={{
                            fontSize: 14
                          }}
                        >
                          {t('report.rr')}
                        </WheelLabel>
                        <img
                          src={DefaultSideImage}
                          style={{ width: 41, height: 41 }}
                        />
                        <UnselectedLine />
                        <UnselectedDot />
                      </ButtonBox>
                    )}
                  </ButtonContainer>
                )
              } else {
                return (
                  <ButtonContainer
                    style={{
                      top: 0,
                      right: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    id={`wheel-${item}-button`}
                  >
                    <ButtonBox>
                      <WheelLabel
                        style={{
                          color: theme.colors.SELECTED_BLUE,
                          fontSize: 14
                        }}
                      >
                        {t('report.rr')}
                      </WheelLabel>
                      <img src={Unselected} style={{ width: 41, height: 41 }} />
                      <UnselectedLine />
                      <UnselectedDot />
                    </ButtonBox>
                  </ButtonContainer>
                )
              }
            } else if (item === 'rl') {
              if (checkSideExit(item)) {
                return (
                  <ButtonContainer
                    style={{
                      bottom: 0,
                      right: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    onClick={() => {
                      if (checkSideExit(item)) {
                        handleSelectedSubmission(item)
                      }
                    }}
                    id={`wheel-${item}-button`}
                  >
                    {selectedSubmission?.side === item ? (
                      <ButtonBox>
                        <SelectedDot />
                        <SelectedLine />
                        <img src={Selected} style={{ width: 41, height: 41 }} />
                        <WheelLabel
                          style={{
                            color: theme.colors.SELECTED_BLUE,
                            fontSize: 14
                          }}
                        >
                          {t('report.rl')}
                        </WheelLabel>
                      </ButtonBox>
                    ) : (
                      <ButtonBox>
                        <UnselectedDot />
                        <UnselectedLine />
                        <img
                          src={DefaultSideImage}
                          style={{ width: 41, height: 41 }}
                        />
                        <WheelLabel
                          style={{
                            fontSize: 14
                          }}
                        >
                          {t('report.rl')}
                        </WheelLabel>
                      </ButtonBox>
                    )}
                  </ButtonContainer>
                )
              } else {
                return (
                  <ButtonContainer
                    style={{
                      bottom: 0,
                      right: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    id={`wheel-${item}-button`}
                  >
                    <ButtonBox>
                      <UnselectedDot />
                      <UnselectedLine />
                      <img src={Unselected} style={{ width: 41, height: 41 }} />
                      <WheelLabel
                        style={{
                          color: theme.colors.SELECTED_BLUE,
                          fontSize: 14
                        }}
                      >
                        {t('report.rl')}
                      </WheelLabel>
                    </ButtonBox>
                  </ButtonContainer>
                )
              }
            } else if (item === 'fl') {
              if (checkSideExit(item)) {
                return (
                  <ButtonContainer
                    style={{
                      bottom: 0,
                      left: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    onClick={() => {
                      if (checkSideExit(item)) {
                        handleSelectedSubmission(item)
                      }
                    }}
                    id={`wheel-${item}-button`}
                  >
                    {selectedSubmission?.side === item ? (
                      <ButtonBox>
                        <SelectedDot />
                        <SelectedLine />
                        <img src={Selected} style={{ width: 41, height: 41 }} />
                        <WheelLabel
                          style={{
                            color: theme.colors.SELECTED_BLUE,
                            fontSize: 14
                          }}
                        >
                          {t('report.fl')}
                        </WheelLabel>
                      </ButtonBox>
                    ) : (
                      <ButtonBox>
                        <UnselectedDot />
                        <UnselectedLine />
                        <img
                          src={DefaultSideImage}
                          style={{ width: 41, height: 41 }}
                        />
                        <WheelLabel
                          style={{
                            fontSize: 14
                          }}
                        >
                          {t('report.fl')}
                        </WheelLabel>
                      </ButtonBox>
                    )}
                  </ButtonContainer>
                )
              } else {
                return (
                  <ButtonContainer
                    style={{
                      bottom: 0,
                      left: 90,
                      cursor: checkSideExit(item) ? 'pointer' : undefined
                    }}
                    key={item}
                    id={`wheel-${item}-button`}
                  >
                    <ButtonBox>
                      <UnselectedDot />
                      <UnselectedLine />
                      <img src={Unselected} style={{ width: 41, height: 41 }} />
                      <WheelLabel
                        style={{
                          color: theme.colors.SELECTED_BLUE,
                          fontSize: 14
                        }}
                      >
                        {t('report.fl')}
                      </WheelLabel>
                    </ButtonBox>
                  </ButtonContainer>
                )
              }
            }

            return <div key={'last'} id={`wheel-unknown-button`}></div>
          })}

          <img src={Car} style={{ width: 243, height: 112 }} />
        </CarImageContainer>
        <PhotoDetailContainer id="detail-container">
          <TopDetailContainer id="judgement-result">
            <Title>{t('report.judgement-result')} : </Title>
            <DescriptionRedText
              style={{ color: theme.colors.RED_RIBBON, marginLeft: 5 }}
            >
              {selectedSubmission?.analysisResult &&
              selectedSubmission?.analysisResult.diagnosisResult
                ? selectedSubmission.analysisResult.diagnosisResult
                : '-'}
            </DescriptionRedText>
          </TopDetailContainer>
          <DetailItemContainer id="repair-instructions">
            <Title>{t('report.repair-instructions')} :</Title>
            <Description>
              {selectedSubmission?.analysisResult &&
              selectedSubmission?.analysisResult.repairInstruction
                ? selectedSubmission.analysisResult.repairInstruction
                : '-'}
            </Description>
          </DetailItemContainer>
          <DetailItemContainer id="technician-judgement-result">
            <Title>{t('report.technician-judgement-result')} :</Title>
            <Description>
              {selectedSubmission?.technicianJudgementResult
                ? selectedSubmission.technicianJudgementResult
                : '-'}
            </Description>
          </DetailItemContainer>
          <DetailItemContainer id="reasons">
            <Title> {t('report.reasons')} :</Title>
            <Description>
              {selectedSubmission?.judgementResult
                ? selectedSubmission.judgementResult
                : '-'}
            </Description>
          </DetailItemContainer>
          <DetailItemContainer id="repair-method">
            <Title>{t('report.repair-method')} :</Title>
            <Description>
              {selectedSubmission?.technicianRepairMethod
                ? selectedSubmission.technicianRepairMethod
                : '-'}
            </Description>
          </DetailItemContainer>
          <DetailItemContainer id="leakage-level">
            <Title>{t('report.leakage-level')} :</Title>
            <Description>
              {selectedSubmission?.leakageLevelDescription
                ? selectedSubmission.leakageLevelDescription
                : '-'}
            </Description>
          </DetailItemContainer>
        </PhotoDetailContainer>
      </PhotoColContainer>
      <LineDivider />
      <PhotoColContainer
        style={{ flex: 1, display: 'flex', overflow: 'hidden', minHeight: 600 }}
      >
        {/* IMAGES COMPONENT */}
        <ImagePreview
          ref={refImageCarousel}
          data={selectedSubmission?.images}
          side={selectedSubmission?.side}
          cameraGuideImage={cameraGuideImage}
          setIndexImage={setIndexImage}
          indexImage={indexImage}
        />
      </PhotoColContainer>
      <LineDivider />
      {/* CHAT COMPONENT */}
      <PhotoColContainer
        style={{ flex: 1, display: 'flex', overflow: 'hidden', minHeight: 600 }}
      >
        <ImagePreviewForm
          commentList={commentList}
          handleCreateComment={handleCreateComment}
          handleRemove={handleRemove}
          renderFlag={renderFlag}
          designGroupOption={designGroupOption}
          formValue={formValue}
          setFormValue={setFormValue}
          handleChangeJudgement={handleChangeJudgement}
          loadingComment={loadingComment}
          loadingFrom={loadingFrom}
        />
      </PhotoColContainer>
    </RowContainer>
  )
}

export default PhotoReport
