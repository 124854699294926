import React from 'react'
// eslint-disable-next-line no-unused-vars
import { OverlayFrontWrapper, OverlayBackWrapper } from './Overlay.style'

const Overlay = ({ side, cameraGuideImage }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        left: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <img
        src={cameraGuideImage}
        alt={side + '-camera'}
        style={{
          width: 'auto',
          height: 400
        }}
      />
      {/* {side === 'fr' || side === 'fl' ? (
        <OverlayFrontWrapper>
          <div className="red-line-y" />
          <div className="yellow-box" />
          <div className="green-box" />
          <img
            src={cameraGuideImage}
            alt={side + '-camera'}
            style={{
              width: 'auto',
              height: 400
            }}
          />
        </OverlayFrontWrapper>
      ) : (
        <OverlayBackWrapper>
          <div className="red-line-y" />
          <div className="red-line-x" />
          <div className="yellow-box" />
          <div className="green-circle" />
          <img
            src={cameraGuideImage}
            alt={side + '-camera'}
            style={{
              width: 'auto',
              height: 400
            }}
          />
        </OverlayBackWrapper>
      )} */}
    </div>
  )
}

export default Overlay
