import React from 'react'
import PaginationDot from './PaginationDot'
import { PaginationContainer } from './Pagination.style'

const Pagination = ({ index, dots, onChangeIndex }) => {
  const handleClick = (event, i) => {
    onChangeIndex(i)
  }

  const children = []

  for (let i = 0; i < dots; i += 1) {
    children.push(
      <PaginationDot
        key={i}
        index={i}
        active={i === index}
        onClick={handleClick}
      />
    )
  }

  return <PaginationContainer>{children}</PaginationContainer>
}

export default Pagination
