const VIDEO_FORMATS = ['.mp4', '.mov', '.3gp', '.webm', '.mkv']
const IMAGE_FORMATS = ['.png', '.jpg', '.jpeg', '.gif']

export const isVideoFormat = (url) =>
  VIDEO_FORMATS.some((format) => url.includes(format))

export const isImageFormat = (url) =>
  IMAGE_FORMATS.some((format) => url.includes(format))

export const checkFileType = (url) => {
  if (url.includes('.pdf')) {
    return 'pdf'
  } else if (isVideoFormat(url)) {
    return 'video'
  } else if (isImageFormat(url)) {
    return 'photo'
  } else {
    return 'audio'
  }
}
