import React from 'react'
import Icon from '../Icon/Icon'
import { BaseButton, BaseIconButton } from './Button.style'

const Button = ({ className, color, icon, ...props }) => {
  const Component = icon ? BaseIconButton : BaseButton

  const iconProps = React.useMemo(() => {
    return icon
      ? {
          icon: <Icon icon={icon} />
        }
      : {}
  }, [icon])

  return (
    <Component
      {...{
        className,
        $color: color,
        ...iconProps,
        ...props
      }}
    />
  )
}

export default Button
