import styled from '@emotion/styled'
import { Sidenav as BaseSidenav, Nav as BaseNav } from 'rsuite'

const radius = '10px'
const tailWidth = '7px'

export const Nav = styled(BaseNav)`
  .rs-nav-item,
  .rs-nav-item-content {
    background: inherit !important;
  }

  .rs-nav-item-content {
    height: 60px !important;
  }
  .rs-icon {
    color: #fff;
    font-size: 20px !important;
    left: 18px !important;
  }

  .rs-nav-item-active {
    position: relative;
    width: calc(100% + ${tailWidth});
    padding-left: calc(${tailWidth});
    margin-left: calc(-${tailWidth});
    color: #fff;
  }

  .rs-nav-item-active::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    left: 0px;
    background: #fff;
    width: calc(${tailWidth} + 3px);
    height: 100%;
    border-radius: 0 ${radius} ${radius} 0;
  }
`

Nav.Item = BaseNav.Item // IMPORTANT: can't use with styled function

export const Sidenav = styled(BaseSidenav)`
  background: inherit;
`

Sidenav.Header = styled(BaseSidenav.Header)`
  margin: 25px 0;
`

Sidenav.Body = BaseSidenav.Body
