import {
  Checkbox as BaseCheckbox,
  CheckboxGroup as BaseCheckboxGroup
} from 'rsuite'
import styled from '@emotion/styled'
import { centerStyle } from '@assets/styles/styles'

export const CheckboxHeader = styled.div`
  background-color: #f3f4f6;
`

export const Checkbox = styled(BaseCheckbox)`
  &.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  &.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #1890ff !important;
    background-color: #1890ff !important;
  }

  .rs-checkbox-checker .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #1890ff !important;
  }
`

export const CheckboxGroup = styled(BaseCheckboxGroup)``

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
`

export const LoaderContainer = styled.div`
  height: 200px;
  ${centerStyle}
`
