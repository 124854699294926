import styled from '@emotion/styled'

export const OverlayFrontWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-37%);
  height: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .yellow-box {
    width: 95px;
    height: 111px;
    opacity: 0.5;
    background-color: #e0c861;
  }
  .green-box {
    margin-bottom: 11px;
    width: 60px;
    height: 73px;
    opacity: 0.5;
    background-color: #a2c67d;
  }
  .red-line-y {
    position: absolute;
    border-left: 3px dashed red;
    height: 100%;
    opacity: 0.5;
  }
`

export const OverlayBackWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-60%);
  height: 333px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .yellow-box {
    width: 74px;
    height: 53px;
    opacity: 0.5;
    background-color: #e0c861;
    margin-top: 9px;
  }
  .green-circle {
    width: 47px;
    height: 47px;
    opacity: 0.5;
    border-radius: 100px;
    background-color: #a2c67d;
    margin-bottom: 5px;
  }
  .red-line-y {
    position: absolute;
    border-left: 3px dashed red;
    height: 100%;
    opacity: 0.5;
  }
  .red-line-x {
    position: absolute;
    top: 303px;
    border-top: 3px dashed red;
    width: 55px;
    opacity: 0.5;
  }
`
